import { createStore } from 'vuex'

// Create a new store instance.
export const store = createStore({
  state () {
    return {
      authenticated: false,
      user: null
    }
  },
  mutations: {
    login (user) {
      this.state.authenticated = true;
      this.state.user = user;
    },
    logout(){
      this.state.authenticated=false;
      this.state.user = null;
    }
  }
})
