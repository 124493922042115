import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import {CognitoUserPool} from 'amazon-cognito-identity-js';
//import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {store} from '../store';
import router from '../router';

//https://auth.crazysignladies.com/login?client_id=3erhrflmodji586eg20smeg9dj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=http://localhost:8080/login/oauth2/code/cognito
const CLIENT_ID = "3erhrflmodji586eg20smeg9dj";//process.env.VUE_APP_COGNITO_CLIENT_ID;
const APP_DOMAIN = "auth.crazysignladies.com"; //process.env.VUE_APP_COGNITO_APP_DOMAIN;
const REDIRECT_URI = process.env.NODE_ENV == 'development' ? "http://localhost:8080/login/oauth2/code/cognito" : 'https://admin.crazysignladies.com/login/oauth2/code/cognito'; //process.env.VUE_APP_COGNITO_REDIRECT_URI;
const USERPOOL_ID = "us-east-1_4gjhJnzkw";//process.env.VUE_APP_COGNITO_USERPOOL_ID;
const REDIRECT_URI_SIGNOUT = process.env.NODE_ENV == 'development' ? "http://localhost:8080/logout" : "https://admin.crazysignladies.com/logout"; //process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
//const APP_URL = process.env.VUE_APP_APP_URL;

var userPoolData = {
  UserPoolId : USERPOOL_ID,
  ClientId : CLIENT_ID
}

var userPool = new CognitoUserPool(userPoolData);

var authData = {
  ClientId : CLIENT_ID, // Your client id here
  AppWebDomain : APP_DOMAIN,
  TokenScopesArray : ['openid', 'email'],
  RedirectUriSignIn : REDIRECT_URI,
  RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
  UserPoolId : USERPOOL_ID,
}

var auth = new CognitoAuth(authData);
var storageHelper = new StorageHelper();
var storage = storageHelper.getStorage();


auth.userhandler = {
  onSuccess: function(result) {
    console.log("On Success result", result);    
    getUserInfo().then(response => {
        store.commit('login', response);
        console.log(response);
        router.push('/');
    });
  },
  onFailure: function(err) {
    store.commit('logout');
    alert('failed to login');
    console.log(err);
    router.push('/');
    //IndexRouter.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
  },  
};

function getUserInfo(){
    var jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = 'https://'+ auth.getAppWebDomain() + '/oauth2/userInfo';
    var requestData = {
      headers: {
        'Authorization': 'Bearer '+ jwtToken
      }
    }
    return axios.get(USERINFO_URL, requestData).then(response => {
      return response.data;
    });
}

function getUserInfoStorageKey(){
  var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
  var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
  var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
  return userInfoKey;
}

export default{
  auth: auth,
  userPool: userPool,
  login(){
    auth.getSession();
  },
  logout(){
    if (store.state.authenticated) {
      console.log('logging out');
      var userInfoKey = this.getUserInfoStorageKey();
      auth.signOut();
      storage.removeItem(userInfoKey);      
    }
  },
  getUserInfoStorageKey,
  parseCognitoWebResponse() {
    auth.parseCognitoWebResponse(window.location.href);
  }
}



