import { createRouter, createWebHistory } from 'vue-router'
import {store} from "../store"
import auth from "../auth/auth.js"
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
  {
   // eslint-disable-next-line no-unused-vars
    path: '/login', beforeEnter(to, from, next) {      
      auth.auth.getSession();
    }
  },
  {
    path: '/login/oauth2/code/cognito',
    // eslint-disable-next-line no-unused-vars
    beforeEnter(to,from,next){
      auth.parseCognitoWebResponse();
    }
  },
  {
    // eslint-disable-next-line no-unused-vars
    path: '/logout',  beforeEnter(to, from, next){
      auth.logout();
      next("/");
    }
  },
  {
    path: '/edit-content',
    name: 'Content Editor',
    meta: { requiresLogin: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/ContentEditor.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && !store.state.authenticated) {      
      next("/")
  } else {
      next()
  }
})

export default router
