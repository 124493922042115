<template>
  <div id="nav"  v-if="authenticated">
    <router-link to="/">Home</router-link> |
    <router-link to="/edit-content"> Edit Content |</router-link>
    <router-link to="/logout"> Logout</router-link>
  </div>
  <div id="nav"  v-if="!authenticated">
    <a href="/login">Login</a> |
  </div>
  <router-view />
</template>
<script>

import { defineComponent } from 'vue'

export default defineComponent({
  setup() {    
  },
  computed:{
    authenticated(){return this.$store.state.authenticated}
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
